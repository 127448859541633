import React from 'react';
import {Strip, VStack, HStack, Spacer, Separator, Paragraph} from './AuxiliaryViews'
import LogoStrip from './LogoStrip'
import FeatureTitleStrip from './FeatureTitleStrip'
import FeatureDescriptionStrip from './FeatureDescriptionStrip'
import FeatureQuoteStrip from './FeatureQuoteStrip'
import MinorFeaturesStrip from './MinorFeaturesStrip'
import Footer from './Footer'
import Strings from './Strings'

export default function HomePage(){


    return (
        <div className="homepage">
            <LogoStrip />

            <FeatureDescriptionStrip title={Strings.ImmersiveExperience.title} subtitle={Strings.ImmersiveExperience.subtitle} paragraphs={Strings.ImmersiveExperience.paragraphs} videoName="ImmersiveExperienceDemo" altName="Immersive Experience" gradient="Purple" reversed={false}/>

            <FeatureDescriptionStrip title={Strings.Dashboard.title}  subtitle={Strings.Dashboard.subtitle} paragraphs={Strings.Dashboard.paragraphs} videoName="DashboardDemo" altName="Dashboard" gradient="Orange" reversed={true}/>

            <FeatureDescriptionStrip title={Strings.FloorPlan.title} subtitle={Strings.FloorPlan.subtitle} paragraphs={Strings.FloorPlan.paragraphs} videoName="FloorPlanDemo" altName="Floor Plan" gradient="Blue" reversed={false}/>

            <FeatureDescriptionStrip title={Strings.ARRemote.title} subtitle={Strings.ARRemote.subtitle} paragraphs={Strings.ARRemote.paragraphs} videoName="ARRemoteDemo" altName="AR Remote" gradient="Yellow" reversed={true}/>
            
            <FeatureDescriptionStrip title={Strings.LightingPresets.title} subtitle={Strings.LightingPresets.subtitle} paragraphs={Strings.LightingPresets.paragraphs} videoName="LightingPresetsDemo" altName="Lighting Presets" gradient="Lights" reversed={false}/>

            

            <MinorFeaturesStrip minorFeatures={Strings.MinorFeatures}/>
            <Footer/>


            <svg width='0' height='0'>
                <filter id='ambilight' y='-50%' x='-50%' width='200%' height='200%'>
                    <feGaussianBlur in='SourceGraphic' stdDeviation='80' result='blurred' />
                    <feComponentTransfer in='blurred' result='fadedBlurred'>
                        <feFuncA type='linear' slope='0.5' />  
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode in='fadedBlurred' />
                        <feMergeNode in='SourceGraphic' />
                    </feMerge>
                </filter>
            </svg>

            
        </div>
    )
}
