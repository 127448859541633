import React from 'react';
import {Strip, VStack, HStack, Spacer, Paragraph, ZStack, VideoWithBackground, FadeInSection} from './AuxiliaryViews'
import { useMediaQuery } from 'react-responsive'

export default function FeatureDescriptionStrip(props) {

    const isMobile = useMediaQuery({ maxWidth: 800 })

    var paragraphs = props.paragraphs || []
    var pComponents = paragraphs.map((item, index) => {
        return <Paragraph key={index} text={item} />
    })

    var media;
    if (props.imageName != null){
        media = <img src={"./assets/" + props.imageName} className="featureImage" alt={props.altText}/>
    }
    else if (props.videoName != null){
        media = <video className="featureImage" src={"./assets/" + props.videoName + ".mp4"} playsInline muted autoPlay loop />
    }


    if(isMobile){
        return (
            <Strip>
                <Spacer size="l"/>
                <VStack className="padding2">
                    <div className={"featureTitle " + "textGradient" + props.gradient}>{props.title}</div>
                    <Spacer size="s"/>
                    <div className={"featureSubtitle"}>{props.subtitle}</div>
                    <div className="featureDescription">{pComponents}</div>
                    <div className="featureMedia">
                        {media}
                    </div>
                </VStack>
                
            </Strip>
        )
    }
    

    const descriptionBlock = (
        <VStack className="width45">
            <div className={"featureTitle " + "textGradient" + props.gradient}>{props.title}</div>
            <Spacer size="s"/>
            <div className="featureSubtitle">{props.subtitle}</div>
            <div className="featureDescription">{pComponents}</div>
        </VStack>
    )

    const imageBlock = (
        <div className="featureMedia width45">
            {media}
        </div>
    )

    const spacer = <div className='width5'></div>

    var blocks = [descriptionBlock, spacer, imageBlock]

    if(props.reversed) { blocks.reverse()}

    return (
        <Strip>
            <Spacer size="xl"/>
            <HStack>
                {blocks}
            </HStack>
        </Strip>
    )

}