class InfoPageContent{
    constructor(props){
        let {title, sections} = props
        this.title = title
        this.sections = sections
    }
}

class Section{
    constructor(props){
        let {name, paragraphs} = props
        this.name = name
        this.paragraphs = paragraphs
    }
}

class MinorFeature{
    constructor(props){
        let { name, description} = props
        this.name = name
        this.description = description
    }
}

class Strings {

    static Dashboard = {
        title: "Dashboard",
        subtitle: "Beautiful and Powerful Widgets",
        paragraphs: [
            "From a minimalist dashboard on iPhone to an extensive dashboard on iPad we got you covered, widgets are customizable!",
            "Reveal more controls just by tapping on any widget element (title, color palette, music cover)"
        ] 
    };

    static FloorPlan = {
        title: "Floor Plan",
        subtitle: "Stop scrolling! Navigate.",
        paragraphs: [
            "Better organisation by placing markers and widgets on the floor plan of your home",
            "Zoom in to view the detail, zoom out to , this way you can seamlessly go from controlling a single accessory to your entire home.",
        ] 
    };

    static ARRemote = {
        title: "AR Remote",
        subtitle: "Just point with your iPhone",
        paragraphs: [
            "Go beyond the boundaries of your phone and interact with smart accessories in real life without even looking at the screen!",
            "Place markers on your home using Augmented Reality. Now you will only need to point towards the accessory you want to control and it will appear right at your fingertips so you can keep your eyes where it matters."
        ] 
    };

    static LightingPresets = {
        title: "Lighting Presets",
        subtitle: "Set the ambiance with just a tap",
        paragraphs: [
            "Create a colorful palette from and image and seamlessly apply it to your lightbulbs.",
            "Lighting Presets are conveniently available on Lights and Panel Widgets"
        ] 
    };


    static ImmersiveExperience = {
        title: "Immersive Experience",
        subtitle: "Bring your smart home to life on Vision Pro",
        paragraphs: [
            "Spatial Widgets stay exactly where you place them, snap to walls, and intelligently minimize to keep your view clear.",
            "Lift your Wrist and control your smart home, like in your favorite Sci-Fi!"
        ]
    };



    static PrivacyPolicy = new InfoPageContent({
        title: "Privacy Policy",
        sections: [
            new Section({
                name: "Collected information",
                paragraphs: [
                    "Homerise collects only information necessary to the configuration of the application. This includes anonymous ID's provided by HomeKit as well as any specific configuration performed by the user in the application like markers, floor plans, Augmented Reality data, scenes and presets.",
                    "Your information is store exclusively on your iCloud account, which already provides a cryptography layer."
                ]
            }),
            new Section({
                name: "Shared information",
                paragraphs: [
                    "Homerise does not share your data with any third-party service."
                ]
            }),
            new Section({
                name: "Data deletion",
                paragraphs: [
                    "Since all the information is store in your iCloud account, you may go to your iCloud settings and delete it anytime."
                ]
            })
        ]
    });

    static MinorFeatures = [
        new MinorFeature({
            name: "Presets",
            description: "Define behaviors or states beforehand and apply them easily to your accessories. Presets integrate with markers and automations."
        }),
        new MinorFeature({
            name: "Scenes",
            description: "Create scenes efficiently with groups of actions, dynamic room groups and presets."
        }),
        new MinorFeature({
            name: "iCloud",
            description: "All the information is securely stored in your iCloud account"
        })
    ]

    
}




export default Strings