import React from 'react';
import ReactDOM from 'react-dom';
import {Strip, VStack, HStack, ZStack, Spacer} from './AuxiliaryViews'

export default function LogoStrip(props){

        return (
            <Strip className="fullHeight logoStrip">
                <HStack>
                    <VStack>
                        <Spacer size="xl"/>
                        <ZStack>
                                <ZStack >
                                    <img className="logoBackground" src="./assets/HomeriseLogo.png" alt="logo"/>
                                    <div className="logoBackgroundCircle"></div>
                                </ZStack>
                                
                                <VStack>
                                    <div className="logoImageContainer">
                                        <img src="./assets/HomeriseLogo.png" alt="logo" width="150px" height="150px" />
                                    </div>
                                    <Spacer size="s"/>
                                    <div className="appName">Homerise</div>
                                    <Spacer size="xs"/>
                                    <div className="slogan">Bring your smart home to life!</div>
                                </VStack>                                
                        </ZStack>
                        <VStack>
                            <Spacer size="m"/>
                            <a href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1439944386" target="_blank">
                                <img src="./assets/AppStoreBadge.svg"></img>
                            </a>
                        </VStack>
                        
                    </VStack>
                </HStack>
            </Strip>
        )
    }

{/* <a>
    <img src="./assets/AppStoreBadge.svg" alt="logo" width="150px" height="150px" />
</a> */}
